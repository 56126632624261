<template>
  <!-- Sales By Country Table Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ padding: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6>Detalhes dos Produtos</h6>
        </a-col>
        <a-col
          :span="24"
          :md="12"
          style="display: flex; align-items: center; justify-content: flex-end"
        >
        </a-col>
      </a-row>
    </template>
    <a-table
      :columns="columns"
      :showHeader="false"
      :data-source="data"
      :pagination="false"
    >
      <a-space
        slot="members"
        slot-scope="members"
        :size="-12"
        class="avatar-chips"
      >
        <template v-for="member in members">
          <a-avatar :key="member" size="small" :src="member" />
        </template>
      </a-space>

      <template slot="flag" slot-scope="flag">
        <img :src="flag" width="25" />
      </template>

      <template slot="offer" slot-scope="offerName">
        <p class="m-0 text-sm font-semibold text-muted">Oferta:</p>
        <h6 class="m-0">{{ offerName }}</h6>
      </template>

      <template slot="amount" slot-scope="amountValue">
        <p class="m-0 text-sm font-semibold text-muted">Total:</p>
        <h6 class="m-0">{{ amountValue }}</h6>
      </template>

      <template slot="click" slot-scope="clickValue">
        <p class="m-0 text-sm font-semibold text-muted">Clique:</p>
        <h6 class="m-0">{{ clickValue }}</h6>
      </template>

      <template slot="conversion" slot-scope="conversionValue">
        <p class="m-0 text-sm font-semibold text-muted">Conversão:</p>
        <h6 class="m-0">{{ conversionValue }}</h6>
      </template>
    </a-table>
  </a-card>
  <!-- / Sales By Country Table Card -->
</template>

<script>
const tableColumns = [
  {
    title: "",
    dataIndex: "logo",
    scopedSlots: { customRender: "flag" },
    width: 50,
    class: "p-15",
  },
  {
    title: "",
    dataIndex: "offer",
    scopedSlots: { customRender: "offer" },
    class: "pl-10",
  },
  {
    title: "",
    dataIndex: "amount",
    scopedSlots: { customRender: "amount" },
  },
  {
    title: "",
    dataIndex: "click",
    scopedSlots: { customRender: "click" },
  },
  {
    title: "",
    dataIndex: "conversion",
    scopedSlots: { customRender: "conversion" },
  },
];

const tableData = [
  {
    key: "1",
    logo: "https://play.google.com/store/apps/details?id=br.com.credcesta&hl=pt_BR",
    offer: "Credcesta INSS",
    amount: "2,500",
    click: "32",
    conversion: "29.9%",
  },
  {
    key: "2",
    logo: "images/icons/flags/US.png",
    offer: "Facta FGTS",
    amount: "2,500",
    click: "24",
    conversion: "9.9%",
  },
  {
    key: "3",
    logo: "images/icons/flags/US.png",
    offer: "Eccor FGTS",
    amount: "2,500",
    click: "13",
    conversion: "29.9%",
  },
];

export default {
  props: {},
  data() {
    return {
      columns: tableColumns,
      data: tableData,
    };
  },
};
</script>